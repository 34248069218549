<template>
  <div class="position-relative" style="min-height: 100vh">
    <div
      class="d-flex justify-content-center align-items-center"
      style="min-height: 100vh"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          class="spongaGif"
          src="../../assets/Sponga/Sponga_loading.gif"
          alt="loading"
        />
        <p class="loadMessage">{{ $t("sponga.to_sponga") }}</p>
      </div>
    </div>

    <div
      class="d-flex flex-column justify-content-center align-items-center position-absolute fixed-bottom"
    >
      <div class="spongaLogo">
        <p class="powerBy">Power by</p>
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <img
            src="../../assets/Sponga/Sponga_Logo.png"
            alt="logo"
            width="10%"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stage } from "../../util/backend";
import CryptoJS from "crypto-js";
export default {
  name: "Loading",
  data() {
    return {
      timeout: null,
    };
  },
  methods: {
    goToSponga() {
      const roleId = this.$store.getters.permissionStatus?.permission?.role
        ?.id_role;
      this.timeout = setTimeout(() => {
        const webStage = stage();
        let link = "https://verscan.sponga.co/";
        if (webStage === "dev") {
          link = "https://dev.sponga.co/";
        }
        if (webStage === "uat") {
          link = "https://uat.sponga.co/";
        }
        const encryptedToken = CryptoJS.AES.encrypt(
          `${roleId}`,
          "Sponga_Verscan_Link"
        ).toString();
        window.location.replace(
          `${link}?right=${encodeURIComponent(encryptedToken)}&locale=${
            this.$i18n.locale
          }&version=1`
        );
      }, 2000);
    },
  },
  mounted() {
    this.goToSponga();
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },
};
</script>

<style>
.loadMessage {
  font-weight: 500;
  font-size: 100%;
  text-align: center;
  color: #013f81;
  padding-top: 20px;
}

.powerBy {
  font-size: 100%;
  text-align: center;
  margin: 0.5px;
}

.spongaGif {
  max-width: 250px;
  width: 100%;
  height: auto;
}

.spongaLogo {
  max-width: 1000px;
  width: 100%;
  height: auto;
  padding-bottom: 40px;
}
</style>
